import React, { Component } from 'react';
import * as emailjs from 'emailjs-com';
import { sendAuto, sendToMe } from '../../Utils/email';
import { toggleContact } from '../../state/actions/index'
import { connect } from 'react-redux'
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { withTranslation } from "react-i18next";
import { TranslationConstants } from '../../Constants'
import Joi from 'joi-browser';
import style from './style.module.scss';

const schema = Joi.object().keys({
    string: Joi.string().min(1),
    phone: Joi.string().regex(/^[()0-9+-]*$/),
    email: Joi.string().email({ minDomainAtoms: 2 }),
});

class Contact extends Component {

    state = {
        name: "",
        email: "",
        phone: "",
        message: "",
        disabled: true,
        validName: this.props.name ? true : false,
        validEmail: this.props.email ? true : false,
        validPhone: this.props.phone ? true : false,
        validMessage: this.props.message ? true : false,
        errorLabelName: false,
        errorLabelPhone: false,
        errorLabelEmail: false,
        errorLabelMessage: false
    }

    onSend = (e) => {
        e.preventDefault()
        this.setState({
            disabled: true,
            name: "",
            email: "",
            phone: "",
            message: "",
        })

        // sendAuto(this.state.email, this.state.name).then((response) => {
        // })
        sendToMe(this.state.message, this.state.email, this.state.name, this.state.phone).then((response) => {
            this.props.dispatch(toggleContact(false));
        })

    }

    onChange = ({ target }) => {
        let { name, value } = target;
        let { validEmail, validPhone, validMessage, validName } = this.state;
        // let phoneNumber = parsePhoneNumberFromString(`${this.state.phone}`, 'US');

        switch (name) {
            case "name":
                var err = Joi.validate({ string: value }, schema);
                if (err.error) {
                    this.setState({
                        validName: false,
                        errorLabelName: true,
                        disabled: true,
                        [name]: value
                    })
                } else {
                    let disabled = true;
                    if (validEmail && validPhone && validMessage) {
                        disabled = false;
                    }
                    this.setState({
                        [name]: value,
                        validName: true,
                        errorLabelName: false,
                        disabled: disabled,
                        [name]: value
                    })
                }
                break;
            case "email":
                var err = Joi.validate({ email: value }, schema);

                if (err.error) {
                    this.setState({
                        validEmail: false,
                        errorLabelEmail: true,
                        disabled: true,
                        [name]: value
                    })
                } else {
                    let disabled = true;
                    if (validName && validPhone > 0 && validMessage) {
                        disabled = false;
                    }
                    this.setState({
                        validEmail: true,
                        errorLabelEmail: false,
                        disabled: disabled,
                        [name]: value
                    })
                }
                break;
            case "phone":
                let text = new AsYouType('US').input(value);
                if (value.length > this.state.phone.length) {
                    value = text
                }
                if (value.length !== 14 && value.length !== 0) {
                    this.setState({
                        errorLabelPhone: true,
                        validPhone: false,
                        disabled: true,
                        [name]: value
                    }
                    )
                } else {
                    let disabled = true;
                    if (validEmail && validMessage && validName) {
                        disabled = false;
                    }
                    this.setState({
                        validPhone: true,
                        errorLabelPhone: false,
                        disabled: disabled,
                        [name]: value
                    })
                }
                break;
            case "message":
                var err = Joi.validate({ string: value }, schema);
                if (err.error) {
                    this.setState({
                        validMessage: false,
                        errorLabelMessage: true,
                        disabled: true,
                        [name]: value
                    })
                } else {
                    let disabled = true;
                    if (validEmail && validPhone && validName) {
                        disabled = false;
                    }
                    this.setState({
                        validMessage: true,
                        errorLabelMessage: false,
                        disabled: disabled,
                        [name]: value
                    })
                }
                break;
            default:
                this.setState({
                    [name]: value
                })
                break;
        }
    }

    warningName() {
        return this.state.errorLabelName ? <span className={style.errorLabel}>Please enter a valid Name.</span> : null
    }

    warningPhone() {
        return this.state.errorLabelPhone ? <span className={style.errorLabel}>Please enter a valid phone number.</span> : null
    }

    warningEmail() {
        return this.state.errorLabelEmail ? <span className={style.errorLabel}>Please enter a valid Email.</span> : null
    }

    warningMessage() {
        return this.state.errorLabelMessage ? <span className={style.errorLabel}>Please enter a valid Message.</span> : null
    }

    render() {
        const { t } = this.props;
        return this.props.contactOpen ? (

            <div className={style.contactWrapper}>
                <div className={style.clickable} onClick={(e) => this.props.dispatch(toggleContact(false))}>
                </div>
                <div className={style.all}>
                    <div className={style.header}>
                        <h1>{t(TranslationConstants.contactUs)}</h1>
                        <i onClick={(e) => this.props.dispatch(toggleContact(false))} className={`material-icons close-modal ${style.contactExit}`}>clear</i>
                    </div>
                    <div className={style.contentWrapper}>
                        <div className={style.flexWrapper}>
                            <form className={style.form}>
                                <div className={style.inputGroup}>
                                    <label>{t(TranslationConstants.name)} {this.warningName()}</label>
                                    <input onChange={this.onChange} name="name" value={this.state.name} className={style.input} type="text" />
                                </div>
                                <div className={style.inputGroup}>
                                    <label>{t(TranslationConstants.email)}</label>
                                    <input onChange={this.onChange} name="email" value={this.state.email} className={style.input} type="text" />
                                </div>
                                <div className={style.inputGroup}>
                                    <label>{t(TranslationConstants.phone)} {this.warningPhone()}</label>
                                    <input onChange={this.onChange} name="phone" value={this.state.phone} className={style.input} type="text" />
                                </div>
                                <div className={style.inputGroup}>
                                    <label>{t(TranslationConstants.message)} {this.warningMessage()}</label>
                                    <textarea onChange={this.onChange} name="message" value={this.state.message} className={style.textArea} type="text" />
                                </div>
                            </form>
                        </div>
                        <button onClick={this.onSend} className={style.submitButton} disabled={this.state.disabled ? 'disabled' : ''}>{t(TranslationConstants.submit)}</button>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default connect((state) => {
    return {
        contactOpen: state.contactOpen
    }
})(withTranslation()(Contact))