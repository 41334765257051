import React from 'react';
import style from './style.module.scss';
import PropTypes from 'prop-types';

const Modal = props => {
    return (
        <div className={style.wrapper}>
            <div className={style.content}>
                {props.children}
            </div>
            <div onClick={props.onDismiss} className={style.clickableTint}></div>
        </div>
    );
};

Modal.propTypes = {
    onDismiss: PropTypes.func
};

Modal.defaultProps = {
    onDismiss: () => { console.warn(`No 'onDismiss' prop in the modal!`) }
}

export default Modal;