import React, { useEffect } from 'react'
import { TweenMax } from 'gsap';
import H from '../../images/H.png'
import style from './style.module.scss'

const LoadingScreen = props => {

    const handleAnimate = () => {
        TweenMax.to('#Holsum_H_Loading', 0.3, {
            scale: 1.2,
            yoyo: true,
            repeat: -1
        })
    }


    useEffect(() => {
        handleAnimate()
    })


    return (
        <div className={style.wrapper}>
            <img id="Holsum_H_Loading" src={H} />
        </div>
    );
};

export default LoadingScreen;