import React from 'react'
import style from './style.module.scss'
import NewsCell from '../NewsCell'
import { useTranslation } from "react-i18next";
import { TranslationConstants } from '../../Constants'
import Scrollable from '../Scrollable'

const News = () => {
    const { t } = useTranslation();
    return (
        <div className={style.newsContainer}>
            <section>
                <div className={style.socialMediaSectionHeader}>
                    <span>@holsumpuertorico</span>
                    <span className={style.mediaCopy}>
                        {t(TranslationConstants.followUs)}
                    </span>
                </div>
            </section>
            <NewsCell />
        </div>
    )
}



export default News