
import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { toggleNav } from '../../state/actions'
import style from './style.module.scss';
import PropTypes from 'prop-types';

const Hamburger = props => {
    let { dispatch, navOpen } = props
    const toggle = () => {
        dispatch(toggleNav(!navOpen))
    }

    // ${props.navOpen ? style.open : style.open}`
    return (
        <div className={`${style.navIcon4} ${navOpen ? style.open : ''}`} onClick={toggle}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

Hamburger.propTypes = {
    dispatch: PropTypes.func,
    navOpen: PropTypes.bool
};

export default connect((state, props) => {
    return {
        navOpen: state.navOpen
    }
})(Hamburger);

