/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect, useState } from "react";
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { StaticQuery, graphql } from "gatsby";
import { LanguageTranslations, LanguageOptions } from '../Constants';
import OpenNavbar from './openNavbar';
import Contest from './Contest';
import Contact from './contact';
import NutritionalFacts from './NutritionalFacts';
// import { OpenNavbar, Contact, NutritionalFacts } from './';

import Header from "./Header"
import "./layout.css"

const Layout = ({ children, language }) => {

  const [windowHeight, setWindowHeight] = useState(null);

  useEffect(() => {
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources: LanguageTranslations,
        lng: language,
        fallbackLng: LanguageOptions.es,
        interpolation: { escapeValue: false }
      });
  }, [language])

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  })

  useEffect(() => {
    var waitForWindow = setInterval(() => {
      if (document && window) {
        clearInterval(waitForWindow)
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh);
        setWindowHeight(window.innerHeight)
        const resizeListener = document.addEventListener('resize', () => {
          document.documentElement.style.setProperty('--vh', vh);
          setWindowHeight(window.innerHeight)
        })
      }
    }, 100)
    // return document.removeEventListener(resizeListener, () => { });
  }, [])

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: LanguageTranslations,
      lng: language,
      fallbackLng: LanguageOptions.es,
      interpolation: { escapeValue: false }
    });

  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={() => (
        <div id="globalBody" style={{
          height: windowHeight,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden'
        }}>
          <Contact />
          <Contest />
          <NutritionalFacts />
          <Header />
          <div
            id="content-wrapper"
            style={{
              flex: 1,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}
          >
            <OpenNavbar
              delayTime={300} />
            <div
              id="scrolling"
              style={{
                flex: 1,
                overflow: 'auto',
                flexDirection: 'column'
              }}>
              {children}
            </div>
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {

  children: PropTypes.node.isRequired,
}

export default connect(state => ({ language: state.language }))(Layout);
