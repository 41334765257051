import React, { useEffect, useState } from 'react'
import { FacebookProvider, Page } from 'react-facebook';
import style from './style.module.scss'

const Bullshit = () => {

    let [currentWidth, setWidth] = useState(1500)
    // const resizeFacebook = () => {
    //     let TIMEOUT = null;
    //     window.onresize = () => {
    //         if (TIMEOUT === null) {
    //             TIMEOUT = window.setTimeout(() => {
    //                 TIMEOUT = null;
    //                 //fb_iframe_widget class is added after first FB.FXBML.parse()
    //                 //fb_iframe_widget_fluid is added in same situation, but only for mobile devices (tablets, phones)
    //                 //By removing those classes FB.XFBML.parse() will reset the plugin widths.

    //                 document.querySelector('.fb-page').classList.remove('fb_iframe_widget');
    //                 document.querySelector('.fb-page').classList.remove('fb_iframe_widget_fluid')
    //                 // FB.XFBML.parse();
    //             }, 300);
    //         }
    //     }
    // }

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setWidth(window.innerWidth)
        })
    }, [])



    return (
        <div className={style.cellWrapper}>
            {/* <div>
                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F20531316728%2Fposts%2F10154009990506729%2F&show_text=true&appId=264795790592388&height=300&width=750" style={{ width: "75rem", height: "30rem", border: "none", scrolling: "no", frameborder: "0", allowTransparency: "true", allow: "encrypted-media" }}></iframe>
            </div>
            <div>
                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F20531316728%2Fposts%2F10154009990506729%2F&show_text=true&appId=264795790592388&height=300&width=750" style={{ width: "75rem", height: "30rem", border: "none", scrolling: "no", frameborder: "0", allowTransparency: "true", allow: "encrypted-media" }}></iframe>
            </div>
            <div className={style.facebook}>
                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F20531316728%2Fposts%2F10154009990506729%2F&show_text=true&appId=264795790592388&height=300&width=750" style={{width:"75rem", height: "30rem", border: "none", scrolling: "no", frameborder: "0", allowTransparency: "true", allow: "encrypted-media" }}></iframe>
            </div> */}
                <FacebookProvider appId="334485120808775">
                    <Page href="https://www.facebook.com/Holsumpr/" height="700" width="500" tabs="timeline" />
                </FacebookProvider>
                {/* <button className={style.viewAllPosts}>SEE ALL POSTS</button> */}
        </div >
    )
}



export default Bullshit