import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import { TweenMax } from 'gsap';
import { useTranslation } from "react-i18next";
import { SELECT_LANGUAGE } from '../../state/constants';
import { TranslationConstants, LanguageOptions, LanguageOptionsSelectors } from '../../Constants'
import Icon from '../../icons'
import { toggleNav, toggleContact } from '../../state/actions'
import { DelayUnmount } from '../'
import Select from 'react-select';
// import './openNavbar.css';
import style from './openNavbar.scss';

const Option = (props) => {
    let option = null;
    let icon = null;

    const onMouseLeave = () => {
        TweenMax.to(icon, 0.1, { x: 0 })
    }

    const onMouseEnter = () => {
        TweenMax.to(icon, 0.1, { x: 10 })
    }

    const onClick = () => {
        //TODO: Will have the action of navigating the user here...
        props.toggle(toggleNav(false))
        setTimeout(() => navigate(props.location), 300)
    }

    return (
        <li
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={e => option = e}
            onClick={onClick}
            className={style.option}>{props.label} <i ref={e => icon = e} className={`${style.linkIcon} material-icons`}>navigate_next</i></li>
    )
}

const ContactUsButton = (props) => {
    let option = null;
    let icon = null;
    const { t } = useTranslation();
    const onMouseLeave = () => {
        TweenMax.to(icon, 0.1, { x: 0 })
    }

    const onMouseEnter = () => {
        TweenMax.to(icon, 0.1, { x: 10 })
    }

    const onClick = () => {
        //TODO: Will have the action of navigating the user here...
        props.dispatch(toggleNav(false))
        setTimeout(() => props.dispatch(toggleContact(true)), 300)
    }

    return (
        <li
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={e => option = e}
            onClick={onClick}
            className={style.option}>{t(TranslationConstants.contactUs)} <i ref={e => icon = e} className={`${style.linkIcon} material-icons`}>navigate_next</i></li>
    )
}


const OpenNavbar = (props) => {

    let topNav = null;
    const { t } = useTranslation();
    let listener = null;
    const [iconDimension, setIconDimension] = useState(50);

    useEffect(() => {
        let listener = window.addEventListener("resize", () => {
            props.dispatch(toggleNav(false))
            let { innerWidth } = window
            if (window.innerWidth < 700) {
                setIconDimension(30)
            } else if (window.innerWidth < 1000) {
                setIconDimension(40)
            } else {
                setIconDimension(50)
            }
        })
        TweenMax.set(".top-nav-secondary", { opacity: 0, display: 'flex' })
        TweenMax.from(".top-nav-secondary", 0.7, { x: window.innerWidth, opacity: 0 });
        TweenMax.to(".top-nav-secondary", 0.7, { x: window.innerWidth - topNav.innerWidth, opacity: 1.0, delay: 0.1 });
        return window.removeEventListener(listener, () => { });
    }, [])

    useEffect(() => {
        if (!props.status)
            TweenMax.to(".top-nav-secondary", 0.7, { x: window.innerWidth, opacity: 0, display: 'none' });
    }, [props.status])

    const _handleIconGrow = (id) => {
        TweenMax.to(id, 0.2, { scale: 1.2, opacity: 0.8, transformOrigin: "50% 50%" })
    }

    const _handleIconShrink = (id) => {
        TweenMax.to(id, 0.2, { scale: 1.0, opacity: 1.0, transformOrigin: "50% 50%" })
    }

    let { dispatch } = props

    const translationOptions = [
        { label: "English", value: LanguageOptions.en },
        { label: "Spanish", value: LanguageOptions.es }
    ]

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white', fontSize: '17px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                fontSize: '17px',
                backgroundColor: isFocused && !isSelected ? 'rgba(232,119,34, 0.1)' : isSelected ? 'rgb(232,119,34)' : 'white',
                color: isFocused && !isSelected ? 'black' : isSelected ? 'white' : 'black',
                ':active': {
                    backgroundColor: isFocused ? 'rgb(232,119,34)' : isSelected ? 'rgb(232,119,34)' : 'white',
                    color: isFocused ? 'white' : isSelected ? 'white' : 'black',
                },
            };
        },
        input: styles => ({ ...styles, fontSize: '17px' })
    };
    return (
        <div ref={e => topNav = e} className="top-nav-secondary">
            <div className={style.scrollable}>
                <div className={style.flexWrapper}>
                    <ul className={style.optionsList}>
                        <Option toggle={dispatch} location="/" label={t(TranslationConstants.home)} />
                        <Option toggle={dispatch} location="/products" label={t(TranslationConstants.products)} />
                        <Option toggle={dispatch} location="/recipes" label={t(TranslationConstants.recipes)} />
                        <Option toggle={dispatch} location="/about" label={t(TranslationConstants.aboutUs)} />
                        <Option toggle={dispatch} location="/tienditas" label="Tienditas Holsum" />
                        <ContactUsButton dispatch={dispatch} />
                    </ul>
                    <div className={style.socialText}>
                        <h2>{t(TranslationConstants.socialMedia)}</h2>
                    </div>
                    <div className={style.socialMediaIcons}>
                        <span>
                            <Icon onMouseEnter={_handleIconGrow} onMouseLeave={_handleIconShrink} type="facebook" color={"#1D3A6D"} height={iconDimension} filled={true} />
                        </span>
                        <span>
                            <Icon onMouseEnter={_handleIconGrow} onMouseLeave={_handleIconShrink} type="instagram" color={"#1D3A6D"} width={iconDimension} filled={true} />
                        </span>
                        <span>
                            <Icon onMouseEnter={_handleIconGrow} onMouseLeave={_handleIconShrink} type="twitter" color={"#1D3A6D"} width={iconDimension} filled={true} />
                        </span>
                    </div>
                    <div className={style.languageDropdownWrapper}>
                        <Select
                            defaultValue={LanguageOptionsSelectors[props.language]}
                            options={translationOptions}
                            onChange={(option) => { props.dispatch({ type: SELECT_LANGUAGE, language: option.value }) }}
                            styles={colourStyles}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect((state) => {
    return {
        status: state.navOpen,
        language: state.language
    }
})(DelayUnmount(OpenNavbar));