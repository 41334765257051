import * as emailjs from 'emailjs-com';

export async function sendAuto(to, name) {

    var template_params = {
        "email": to,
        "from_name": process.env.REACT_APP_FROM_NAME,
        "name": name,
        "company_name": process.env.REACT_APP_COMPANY_NAME,
        "from_email": process.env.REACT_APP_FROM_EMAIL
    }

    // var service_id = "default_service";
    // var template_id = "contact_me";
    return emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_SEND, template_params, process.env.REACT_APP_EMAIL_API_KEY)

        .then((response) => {
            return true
        }, (error) => {
            return false;
        });
}

export async function sendToMe(message, email, name, phone = 'N/A', purpose = "General") {
    var template_params = {
        "email": email,
        "name": name,
        "phone": phone,
        "purpose": purpose,
        "message": message,
        "my_email": process.env.REACT_APP_EMAIL,
        "website": process.env.REACT_APP_WEBSITE
    }
    console.log("Sending email...")
    return emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_TO_ME, template_params, process.env.REACT_APP_EMAIL_API_KEY)
        .then((response) => {
            console.log("Sent!...")
            alert("Thank you for contacting us, we will contact you shortly.");
            return true
        }, (error) => {
            alert("Something happened.. Please try again.");
            console.error("EMAILJS ERROR: ", error);
            return false;
        });
}