import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import Content from './NutritionalFactsContent';

const NutritionalFacts = props => {
    return props.isOpen && <Content />
};

NutritionalFacts.propTypes = {

};

export default connect(state => ({ isOpen: state.nutritionalFactsOpen }))(NutritionalFacts); 