import React, { Component } from 'react'
import { TweenMax } from 'gsap'
import { Link } from 'gatsby'
import style from './style.module.scss'
import Roll from 'react-reveal/Roll';
import Zoom from 'react-reveal/Zoom';
import NoImage from '../NoImage';

class ProductCell extends Component {

    themeClass = null;

    onMouseEnter = () => {
        TweenMax.to(this.cell, 0.2, {
            scale: 1.1
        })
    }

    onMouseLeave = () => {
        TweenMax.to(this.cell, 0.2, {
            scale: 1.0
        })
    }

    componentWillMount() {
        switch (this.props.theme) {
            case "orange":
                this.themeClass = style.orange
                break;
            case "blue":
                this.themeClass = style.blue
                break;
            case "neutral":
                this.themeClass = style.neutral
                break;
            default:
                this.themeClass = style.blue
        }
    }

    render() {

        let { name = 'N/A', details = '', photos = [] } = this.props.data
        let { location = '' } = photos[0] || {}
        let productPhotoTypeId = photos.filter(photo => photo.photo_type_id === 1)
        let productPhoto = productPhotoTypeId[0] && productPhotoTypeId[0].location

        return (
            <Zoom>
                <Link ref={e => this.link = e} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} to={`/${!this.props.recipe ? 'single-product' : 'single-recipe'}?id=${this.props.index}`}
                    state={{ index: this.props.index }}
                    className={`${style.productWrapper} ${this.themeClass} ${this.props.disabled ? `${style.disabledLink}` : null}`}>
                    <div ref={e => this.cell = e} className={`${style.circle} ${this.props.circleClass}`}>
                        {location ? <img src={productPhoto ? productPhoto : null} /> : <NoImage />}
                    </div>
                    <div className={style.recipeWrapper}>
                        <h1>{name}</h1>
                    </div>
                    <div className={style.descriptionWrapper}>
                        <h1>{details}</h1>
                    </div>
                </Link>
            </Zoom>
        )
    }
}

export default ProductCell