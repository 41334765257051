import React from 'react';
import style from './style.module.css'

const Scrollable = (props) => {

    let { children, id = null } = props;

    return(
        <div id={id} className={style.scrollableWrapper}>
            {children}
        </div>
    )
}

export default Scrollable