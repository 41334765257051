import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss'

const NoImage = props => {
    return (
        <div className={style.image}>
        <svg fill="#dbdbdb" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"           xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="20rem" height="15rem" viewBox="0 0 398.545 398.545" style={{"enable-background" : ""}}
            xmlSpace="preserve">
        <g>
            <g>
                <g>
                    <path d="M157.122,271.387h123.825c11.651,0,21.1-9.444,21.1-21.096v-90.083c0-6.195-2.688-11.748-6.938-15.607l-41.811,38.417
                        c2.969,6.818,4.626,14.334,4.626,22.232c0,30.812-25.065,55.878-55.878,55.878c-9.576,0-18.595-2.425-26.481-6.687
                        L157.122,271.387z"/>
                    <path d="M242.719,205.234c0-3.84-0.546-7.553-1.546-11.077l-53.471,49.131c4.464,1.688,9.296,2.619,14.344,2.619
                        C224.472,245.907,242.719,227.665,242.719,205.234z"/>
                    <path d="M361.07,83.989l-29.685,27.275c17.128,25.094,27.158,55.4,27.158,88.008c0,86.294-70.206,156.5-156.5,156.5
                        c-37.344,0-71.669-13.155-98.602-35.064l-29.693,27.283c34.452,29.76,79.308,47.781,128.296,47.781
                        c108.352,0,196.5-88.148,196.5-196.5C398.545,156.235,384.626,116.395,361.07,83.989z"/>
                    <path d="M354.679,9.241c-7.474-8.134-20.125-8.669-28.259-1.195L302.221,30.28C272.87,12.814,238.608,2.772,202.045,2.772
                        c-108.352,0-196.5,88.149-196.5,196.5c0,30.652,7.065,59.684,19.641,85.561L6.469,302.031
                        c-8.134,7.475-8.669,20.125-1.195,28.259c3.944,4.293,9.329,6.47,14.732,6.47c4.835,0,9.685-1.742,13.526-5.272L353.483,37.501
                        C361.616,30.026,362.151,17.375,354.679,9.241z M45.544,199.272c0-86.294,70.206-156.5,156.5-156.5
                        c24.777,0,48.226,5.795,69.067,16.091l-87.335,80.248H161.37v-7.159c0-2.648-2.146-4.794-4.794-4.794h-18.284
                        c-2.648,0-4.793,2.146-4.793,4.794v7.159h-10.357c-11.652,0-21.099,9.443-21.099,21.096v54.004l-45.751,42.036
                        C49.361,238.585,45.544,219.367,45.544,199.272z"/>
                </g>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
        </div>
    );
};

NoImage.propTypes = {
    
};

export default NoImage;