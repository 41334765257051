import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { SELECT_LANGUAGE } from '../../state/constants';
import { toggleContact } from '../../state/actions';
import { TranslationConstants, LanguageOptions, LanguageOptionsSelectors } from '../../Constants'
import PropTypes from "prop-types"
import { NavItem } from '../'
import Select from 'react-select';
import style from './style.module.scss'

const NavList = (props) => {
    const { t } = useTranslation();
    const translationOptions = [
        { label: "English", value: LanguageOptions.en },
        { label: "Spanish", value: LanguageOptions.es }
    ]

    const handleContactClick = (e) => {
        props.dispatch(toggleContact(true))
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white', fontSize: '17px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                fontSize: '17px',
                backgroundColor: isFocused && !isSelected ? 'rgba(232,119,34, 0.1)' : isSelected ? 'rgb(232,119,34)' : 'white',
                color: isFocused && !isSelected ? 'black' : isSelected ? 'white' : 'black',
                ':active': {
                    backgroundColor: isFocused ? 'rgb(232,119,34)' : isSelected ? 'rgb(232,119,34)' : 'white',
                    color: isFocused ? 'white' : isSelected ? 'white' : 'black',
                },
            };
        },
        input: styles => ({ ...styles, fontSize: '17px' }),
    };
    return (
        <ul className={style.listWrapper}>
            <NavItem link="/">{t(TranslationConstants.home).toUpperCase()}</NavItem>
            <NavItem link="/products">{t(TranslationConstants.products).toUpperCase()}</NavItem>
            <NavItem link="/recipes">{t(TranslationConstants.recipes).toUpperCase()}</NavItem>
            <NavItem link="/about">{t(TranslationConstants.aboutUs).toUpperCase()}</NavItem>
            <NavItem link="/tienditas">TIENDITAS HOLSUM</NavItem>
            <span className={style.contact} onClick={handleContactClick}>{t(TranslationConstants.contactUs).toUpperCase()}</span>
            <div style={{ width: '120px', marginRight: '15px' }}>
                <Select
                    defaultValue={LanguageOptionsSelectors[props.language]}
                    options={translationOptions}
                    onChange={(option) => { props.dispatch({ type: SELECT_LANGUAGE, language: option.value }) }}
                    styles={colourStyles}
                />
            </div>
        </ul>
    )
}

NavList.propTypes = {
    vertical: PropTypes.bool,
    hAlign: PropTypes.string,
    vAlign: PropTypes.string
}

NavList.defaultProps = {
    vertical: false,
    hAlign: 'center',
    vAlign: 'center',
}

export default connect(state => ({ language: state.language }))(NavList)