import React, { useEffect, useState } from 'react';
// import { useSpring, animated } from 'react-spring'
import Confetti from 'react-confetti'
import style from './style.module.scss';
import { Link } from 'gatsby'
import { TweenMax } from 'gsap';
import Banner1 from '../../images/banner_1.jpg';
import Banner2 from '../../images/banner_2.png';
import Banner3 from '../../images/banner_3.jpg';
import Banner4 from '../../images/pan_de_mallorca.jpg';
import _60Years from '../../images/60_anos.png';
import 'react-responsive-carousel';
import { Carousel } from 'react-responsive-carousel';


const Landing = (props) => {

    const getSize = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    const animateLogo = () => {
        TweenMax.to('#logo', 0.5, { opacity: 1.0 })
        TweenMax.from('#logo', 0.5, { x: -window.innerWidth })
        TweenMax.to('#logo-60', 0.8, { opacity: 1.0 })
        TweenMax.from('#logo-60', 0.8, { y: -window.innerWidth, delay: 0.5 })
        TweenMax.to('#landing-copy', 0.8, { opacity: 1.0 })
        TweenMax.from('#landing-copy', 0.8, { x: window.innerWidth })
    }

    const handleResize = () => {
        setWindowSize(getSize());
    }
    let [initialized, setInitialized] = useState(false)
    let [windowSize, setWindowSize] = useState({});

    useEffect(() => {

        setWindowSize(getSize())
        setInitialized(true)
        animateLogo()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    useEffect(() => {
        setWindowSize(getSize())
    }, [props]);

    return (
        <div className={style.videoWrapper}>
            {/* <Carousel
                className={style.carousel}
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                autoPlay={true}
                stopOnHover={false}
                dynamicHeight
                showStatus={false}
                interval={4000}
            >
                <div>
                    <img id="banner-1" className={style.backgroundImage} src={Banner1} />
                </div>
                <div>
                    <img id="banner-2" className={style.backgroundImage} src={Banner2} />
                </div>
                <div>
                    <img id="banner-3" className={style.backgroundImage} src={Banner3} />
                </div>
                <div>
                    <img id="banner-4" className={style.backgroundImage} src={Banner4} />
                </div>
            </Carousel> */}
            {/* <iframe title="landing-video" className={style.video} src="https://player.vimeo.com/video/409486708?autoplay=1&loop=0&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen" webkitAllowFullscreen mozAllowFullscreen allowFullScreen></iframe> */}
            <iframe title="landing-video" className={style.video} src="https://player.vimeo.com/video/714440705?autoplay=1&loop=0&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen" webkitAllowFullscreen mozAllowFullscreen allowFullScreen></iframe>

            {/* <div className={style.contentWrapper}>
                {
                    initialized ?
                        <Confetti
                            colors={["#E87722", "#1D3A6D"]}
                            width={windowSize.width}
                            height={windowSize.height}
                        /> : null
                }
                <div className={style.left}>
                </div>
                <div className={style.right}>
                </div>
            </div> */}
        </div>
    )
}

export default Landing;