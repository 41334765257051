import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import Lottie from 'react-lottie';
import Colors from '../../globalStyles/variables.json';
import Animation from '../../images/holsum_loading.json';

const LoadingView = props => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Animation,
        // rendererSettings: {
        //     preserveAspectRatio: 'xMidYMid slice'
        // }
    };

    return (
        <div className={style.wrapper}>
            <Lottie options={{ ...defaultOptions, ...props.options }}
                height={props.size}
                width={props.size} />
            {/* // TODO change loading message to spanish -- added by Glen Turner. */}
            {/* <h2 className={style.label} style={{ color: props.labelColor }}>{`Loading ${props.label}, just a moment...`}</h2> */}
        </div>
    );
};

LoadingView.propTypes = {
    size: PropTypes.number,
    options: PropTypes.func,
    label: PropTypes.string,
    labelColor: PropTypes.string
}

LoadingView.defaultProps = {
    size: 100,
    options: {},
    label: 'content',
    labelColor: 'white'
}

export default LoadingView;