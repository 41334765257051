import React, { useEffect } from 'react'
import style from './style.module.scss'
import Copy from '../Copy'
import { useTranslation } from "react-i18next";
import { TranslationConstants } from '../../Constants'
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce'

const About = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.body.scrollTop = 0;
        document.getElementById("about-wrapper").scrollTop = 0;
        document.documentElement.scrollTop = 0;
        document.getElementById("main-header").scrollIntoView({ behavior: "smooth", block: "start" });
    })
    return (
        <div>
            <div id="about-wrapper" className={style.content}>
                <Bounce left>
                    <div id="about-header" className={style.rectangle}>
                        <h1>{t(TranslationConstants.homeAboutHeader)}</h1>
                    </div>
                </Bounce>
                <Zoom>
                    <Copy color="#EFF8FF" contentLeft={t(TranslationConstants.homeAboutLeftContent)} contentRight={t(TranslationConstants.homeAboutRightContent)} /></Zoom>
                <div className={style.buttonSection}>
                    {/* <Link to="/about"> <button className={style.learnMoreButton}>LEARN MORE</button></Link> */}
                </div>
            </div>
        </div>
    )

}

export default About