import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Script from 'react-load-script';
import { connect } from 'react-redux'
import { GooglePoint } from '../'
import GoogleMapReact from 'google-map-react';
import style from './style.module.scss';
import { toggleContestDetails } from '../../state/actions';

const GoogleMap = props => {

    useEffect(() => {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCoAzOiRYMvxHCe8EPOMpHrCtV6eOvkW3M&address=Km 11.3 PR-111, San Sebastián, Moca 00685, Puerto Rico`).then((e) => {
        })
    }, [])

    return (
        <div className={style.wrapper}>
            {/* <div className={style.banner} onClick={(e) => props.dispatch(toggleContestDetails(true))}>
                <span className={style.copy}>Reglas del Sorteo “Tiendita a Tu Lado”</span>
                <button className={style.learnMore}>Aprende más</button>
            </div> */}
            {/* <Script url={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCoAzOiRYMvxHCe8EPOMpHrCtV6eOvkW3M&libraries=places`} onLoad={handleScriptLoad} /> */}
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyCoAzOiRYMvxHCe8EPOMpHrCtV6eOvkW3M" }}
                defaultCenter={props.center}
                defaultZoom={props.zoom}
                yesIWantToUseGoogleMapApiInternals
            >
                {
                    props.locations.map((location, index) => {
                        return <GooglePoint
                            key={index}
                            index={index}
                            lat={location.coords.lat}
                            lng={location.coords.long}
                            text="My Marker" />
                    })
                }


            </GoogleMapReact>
        </div>
    );
};

GoogleMap.propTypes = {

};

GoogleMap.defaultProps = {
    center: {
        lat: 18.200178,
        lng: -66.664513
    },
    zoom: 10
};

export default connect((state) => {
    return {
        contestDetailsOpen: state.contestDetailsOpen
    }
})((GoogleMap))

