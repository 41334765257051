import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { TweenMax } from 'gsap';

const Copy = ({ contentLeft, headerLeft, headerRight, contentRight, color }) => {

    // const animateCopy = () => {
    //     TweenMax.from('#leftCopy', 0.8, { x: -window.innerWidth, opacity: 0 })
    //     TweenMax.from('#rightCopy', 0.8, { x: window.innerWidth, opacity: 0, delay: 0.4 })
    // }

    useEffect(() => {
        // animateCopy()
    }, [])
    return (
        <div className={style.copyWrapper}>
            <div className={style.copySection} >
                <h5>{headerLeft}</h5>
                <p style={{ backgroundColor: color }}>{contentLeft}</p>
            </div>
            <div className={style.copySection} >
                <h5>{headerRight}</h5>
                <p style={{ backgroundColor: color }}>{contentRight}</p>
            </div>
        </div>
    )
}

Copy.propTypes = {
    contentLeft: PropTypes.string,
    contentRight: PropTypes.string,
    headerLeft: PropTypes.string,
    headerRight: PropTypes.string
}

Copy.defaultProps = {
    contentLeft: null,
    contentRight: null,
    headerLeft: null,
    headerRight: null
}


export default Copy