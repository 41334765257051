import React from 'react'
import style from './style.module.scss'
import { TweenMax } from 'gsap'
import { connect } from 'react-redux'
import { toggleContact } from '../../state/actions/index'
import { NavItem } from '../';
import { useTranslation } from "react-i18next";
import { TranslationConstants } from '../../Constants'
import instagram from '../../images/instagram.svg'
import facebook from '../../images/facebook.svg'
import twitter from '../../images/twitter.svg'
import Logo from '../../images/holsum_logo_no_copyright.png';
import BbbLogo from '../../images/bbb_logo.png';

const SocialIcon = (props) => {
    let self = null;

    const onMouseEnter = () => {
        TweenMax.to(self, 0.2, {
            scale: 1.1
        })
    }

    const onMouseLeave = () => {
        TweenMax.to(self, 0.2, {
            scale: 1.0
        })
    }

    const { imgSrc, link, alt } = props
    return (
        <a ref={ref => self = ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} href={link} target="_blank">
            <span>
                <img className={style.socialMedia} src={imgSrc} alt={alt} />
            </span>
        </a>
    )
}

const Footer = (props) => {
    const { t } = useTranslation();
    const handleContactClick = (e) => {
        props.dispatch(toggleContact(true))
    }

    return (
        <div className={style.footerWrapper}>
            <div className={style.contentWrapper}>
                <div className={style.topContent}>
                    <div className={style.logo}>
                        <img src={Logo} />
                        <img onClick={() => window.open("https://www.bbb.org/us/pr/toa-baja/profile/food-broker/holsum-de-puerto-rico-0633-54000129")} style={{height: 80, width: 'auto'}} src={BbbLogo} />
                    </div>
                </div>
                <div className={style.coloredSpacer}>
                </div>
                <div className={style.siteMapWrapper}>
                    <div className={style.siteMapLeft}>
                        {/* <span className={style.contactUs} onClick={handleContactClick}>{t(TranslationConstants.contactUs)}</span> */}
                    </div>
                    <div className={style.socialMediaIcons}>
                        <span className={style.mediaCopy}>{t(TranslationConstants.socialMedia)}: </span>
                        <SocialIcon imgSrc={facebook} link="https://www.facebook.com/Holsumpr/" alt="FACEBOOK" />
                        <SocialIcon imgSrc={instagram} link="https://www.instagram.com/holsumpuertorico/" alt="INSTAGRAM" />
                        <SocialIcon imgSrc={twitter} link="https://twitter.com/hashtag/holsumpr" alt="TWITTER" />
                    </div>
                    <div className={style.siteMapRight}>
                        <NavItem color='white' link="/">&copy; 2020 Holsum</NavItem>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default connect()(Footer)

