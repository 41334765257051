import React from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../state/constants';
import PropTypes from 'prop-types';
import { Modal } from '../'
import style from './style.module.scss';

const NutritionalFactsContent = props => {
    return (

        <div className={style.contentWrapper}>
            <Modal onDismiss={() => props.dispatch({ type: Actions.TOGGLE_NUTRITION, bool: false })}>
                <img src={props.image.location} style={{ width: 'auto', maxHeight: '90vh', border: '3px solid orange' }} />
            </Modal>
        </div>
    );
};

NutritionalFactsContent.propTypes = {

};

export default connect(state => ({ image: state.nutritionalFactImage }))(NutritionalFactsContent);