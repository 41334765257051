import { Link } from "gatsby"
import { TweenMax } from 'gsap';
import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { NavList, Hamburger } from '../';
import Logo from '../../images/holsum_logo_no_copyright.png';
import style from './style.module.scss';

const Header = () => {
  // const animateLogo = () => {
  //   TweenMax.to('#logo', 0.5, { opacity: 1.0 })
  //   TweenMax.from('#logo', 0.5, { x: -window.innerWidth })
  // }

  useEffect(() => {
    // animateLogo()
  }, [])

  return (
    <header
      id="main-header"
      className={style.headerWrapper}
    >
      <Link
        to="/"
        className={style.link}
      >
        <img id="logo" className={style.logo} src={Logo} />
      </Link>
      <NavList />
      <Hamburger />
    </header>
  )
}

export default Header
