import React from 'react';
import HolsumLogo from '../../../images/Holsum-60-anos.png';
import style from './style.module.scss';
import MadresButton from '../MadresButton';

const MadresPage = props => {
    return (
        <div className={style.wrapper}>
            <img src={HolsumLogo} className={style.holsumLogo} />
            <h1>¡Bienvenido Equipo Holsum!</h1>
            <p>Utiliza el botón para subir la foto con tu mamá en nuestra galería.</p>
            <MadresButton />
        </div>
    );
};

export default MadresPage;