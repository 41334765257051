import React, { useState, useEffect } from 'react';
import { Link } from "gatsby"
import PropTypes from 'prop-types';
import style from './style.module.scss';

const NavItem = props => {

    const [loadedWindow, setLoadedWindow] = useState(null);

    useEffect(() => { setLoadedWindow(window) }, [])

    return (
        <Link to={props.link} className={`${style.link} ${loadedWindow && loadedWindow.location.pathname == props.link && style.linkActive}`} style={props.color && { color: props.color }}>{props.children}</Link>
    );
};

NavItem.propTypes = {
    link: PropTypes.string,
};

NavItem.defaultProps = {
    link: '/',
    children: 'Link'
}

export default NavItem;