import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TweenMax } from 'gsap'
import H from '../../images/H.png'
import Horange from '../../images/H_orange.png'
import { updateLocationCell } from '../../state/actions/index'
import style from './style.module.scss';

const GooglePoint = props => {
    let ref = null;
    useEffect(() => {
        const element = document.getElementById(`point-${props.index}`)
        props.isHovered ? TweenMax.to(element, 0.1, {
            scale: 1.2,
            // background: '#E87722',
            // borderColor: '#1D3A6D'
        }) : TweenMax.to(element, 0.1, {
            scale: 1.0,
            // background: '#1D3A6D',
            // borderColor: '#E87722'
        })
    }, [props.isHovered])
    const onMouseEnter = () => {
        props.dispatch(updateLocationCell(props.index))
    };
    let onMouseLeave = () => {
        props.dispatch(updateLocationCell(null))
    };

    return (
        // <div id={`point-${props.index}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={style.wrapper}>
        !props.isHovered ? <img src={H} id={`point-${props.index}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={style.h} style={{ height: '4rem', width: '4rem' }} />
            : <img src={Horange} id={`point-${props.index}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={style.h} style={{ height: '4rem', width: '4rem' }} />
        // </div>
    );
};

GooglePoint.propTypes = {

};

export default connect((state, props) => ({
    isHovered: state.hoveredCell === props.index
}))(GooglePoint);