import React from 'react';
import style from './style.module.scss';

const MadresButton = props => {
    return (
        <a className={style.buttonWrapper} href="https://app.smartsheet.com/b/form/84cad88df7a04667b4fc55d19134b5c3" target="_blank">
            Presiona aquí
        </a>
    );
};

export default MadresButton;