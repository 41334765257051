import React, { useEffect } from 'react';
import style from './style.module.scss';
import { Link } from 'gatsby'
import { TweenMax } from 'gsap';
import { useTranslation } from "react-i18next";
import { TranslationConstants } from '../../Constants'
import { connect } from 'react-redux'
import { ProductCell, LoadingView } from '../'
import Bounce from 'react-reveal/Bounce'
import Zoom from 'react-reveal/Zoom';

const Family = (props) => {
    let test = null;
    const { t } = useTranslation();
    return (
        <div ref={e => test = e} className={style.familyWrapper}>
            <div className={style.header}>
                <Bounce left>
                    <h1 className="headline">{t(TranslationConstants.meetTheFamily)}</h1>
                </Bounce>
            </div>
            <Zoom>
                <div className={style.productWrapper}>

                    {
                        props.getProductsLoading ? <LoadingView label="products" /> : props.products.map((item, key) => <ProductCell theme={'light'} key={key} index={key} data={item} />)
                    }

                </div>
            </Zoom>

            <Link className={style.link} to='/products'> <button className={style.viewMoreButton}>{t(TranslationConstants.viewMoreProducts)}</button></Link>
        </div>
    )
}

export default connect((state) => {
    return {
        products: state.products.filter((item, index) => index < 5),
        getProductsLoading: state.getProductsLoading
    }
})(Family);

Family.defaultProps = {
    products: []
}